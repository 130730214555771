import React from "react"
import { MailIcon, ChevronDownIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import Collapsible from "react-collapsible"
import { Helmet } from "react-helmet"

const getCurrentYear = () => {
  return new Date().getFullYear();
};

export default function Home() {
  return <div>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Arisa Williams</title>
    <meta name="title" content="Arisa Williams" />
    <meta name="description" content="Arisa Williams is a web developer, a web/graphic designer, and an IT consultant based in Ohio." />
    
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://arisawilliams.com" />
    <meta property="og:title" content="Arisa Williams" />
    <meta property="og:description" content="Arisa Williams is a web developer, a web/graphic designer, and an IT consultant based in Ohio." />
    <meta property="og:image" content="https://arisawilliams.com/og.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="Arisa Williams." />
    <meta property="fb:app_id" content="199441318795105" />
    
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://arisawilliams.com" />
    <meta property="twitter:title" content="Arisa Williams" />
    <meta property="twitter:description" content="Arisa Williams is a web developer, a web/graphic designer, and an IT consultant based in Ohio." />
    <meta property="twitter:image" content="https://arisawilliams.com/og.png" />
    <meta property="twitter:image:alt" content="Arisa Williams." />
  </Helmet>
  <div className="min-h-4 bg-gradient-to-r from-pink-400 via-blue-400 to-green-400">&nbsp;</div>
  <div className="container">

    {/* Intro */}
    <header>
    <div className="my-24 lg:my-28 p-6">
      <h1>Hi! <wbr />I&rsquo;m Arisa<span className="gradient-text">.</span></h1>
      <p className="text-2xl font-bold">A <span className="gradient-text">web developer / web &amp; graphic designer / IT consultant</span> based in Ohio.</p>
      <a href="mailto:arisa.williams@icloud.com"><button className="btn-gradient mt-3">Chat with me <MailIcon className="h-6 w-6 inline" /></button></a>
    </div>
    </header>
    {/* End Intro */}

    {/* Services */}
    <section>
    <div className="dark:shadow-md shadow rounded-lg p-6 dark:bg-gray-900 bg-gray-100 mt-12 two-cols">
      <div className="m-auto order-2 md:order-1">
      <h2>Web Design/​Development</h2>
      <p>I specialize in designing and building responsive websites that look and perform great on every platform and screen size.</p>
      <div className="flex flex-wrap gap-2">
        <div className="tag">HTML</div>
        <div className="tag">CSS</div>
        <div className="tag">JavaScript</div>
        <div className="tag">GatsbyJS</div>
        <div className="tag">React</div>
        <div className="tag">Drupal</div>
        <div className="tag">Joomla</div>
        <div className="tag">Wordpress</div>
        <div className="tag">Webstudio</div>
        <div className="tag">Webflow</div>
        <div className="tag">Framer</div>
        <div className="tag">GraphQL</div>
        <div className="tag">GitHub</div>
        <div className="tag">Vercel</div>
        <div className="tag">Netlify</div>
        <div className="tag">Tailwind CSS</div>
        <div className="tag">Bootstrap</div>
      </div>
      </div>
      <StaticImage 
        src="../images/dev.jpg"
        alt="Coding."
        className="rounded-lg order-1 md:order-2"
      />
    </div>

    <div className="dark:shadow-md shadow rounded-lg p-6 dark:bg-gray-900 bg-gray-100 mt-12 two-cols">
      <StaticImage 
        src="../images/design.jpg"
        alt="Graphic design."
        className="rounded-lg"
      />
      <div className="m-auto">
      <h2>Graphic Design</h2>
      <p>I deliver beautifully created digital and print collaterals, including but not limited to posters, flyers, business cards, social media assets, and banners.</p>
      <div className="flex flex-wrap gap-2">
        <div className="tag">Figma</div>
        <div className="tag">InDesign</div>
        <div className="tag">Illustrator</div>
        <div className="tag">Photoshop</div>
        <div className="tag">Canva</div>
        <div className="tag">Miro</div>
        <div className="tag">Midjourney</div>
        <div className="tag">Ideogram</div>
      </div>
      </div>
    </div>

    <div className="dark:shadow-md shadow rounded-lg p-6 dark:bg-gray-900 bg-gray-100 mt-12 two-cols">
      <div className="m-auto order-2 md:order-1">
      <h2>IT Consulting</h2>
      <p>I identify areas for improvement in your daily workflow and provide digital solutions.</p>
      <div className="flex flex-wrap gap-2">
        <div className="tag">Airtable</div>
        <div className="tag">Notion</div>
        <div className="tag">Zapier</div>
        <div className="tag">IFTTT</div>
        <div className="tag">Shortcuts</div>
        <div className="tag">Pabbly</div>
        <div className="tag">Generative AI</div>
      </div>
      
      </div>
      <StaticImage 
        src="../images/consulting.jpg"
        alt="IT consulting."
        className="rounded-lg order-1 md:order-2"
      />
    </div>
    </section>
    {/* End Services */}

    {/* Works */}
    <section>
    <div className="three-cols mt-20 mb-12">
    <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/jason.png"
          alt="Jason Williams website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://jasonwilliams.ai/" target="_blank" rel="noreferrer noopener">Jason Williams &raquo;</a></h2>
          <p className="year">IT Professional | November, 2024</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">v0</div>
            <div className="tag">GitHub</div>
            <div className="tag">Vercel</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/cleveland-coach.png"
          alt="Cleveland Coach website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://clevelandcoach.com/" target="_blank" rel="noreferrer noopener">Cleveland Coach &raquo;</a></h2>
          <p className="year">Transportation | October, 2024</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/opd.png"
          alt="Oberlin Police Department website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://www.oberlinpd.com/" target="_blank" rel="noreferrer noopener">Oberlin Police Department &raquo;</a></h2>
          <p className="year">Law Enforcement | April, 2024</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Webflow</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/phits.png"
          alt="PHITS—Philosophy in the Schools website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://phits-in-oberlin.com/" target="_blank" rel="noreferrer noopener">Philosophy in the Schools (PHITS) &raquo;</a></h2>
          <p className="year">Education | February, 2024</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/tarokunchi.png"
          alt="Tarokunchi website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://tarokunchi.com/" target="_blank" rel="noreferrer noopener">太郎くん家 (Tarokunchi) &raquo;</a></h2>
          <p className="year">Animals | Spring, 2023</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/starrbrowz.png"
          alt="StarrBrowz website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://www.starrbrowz.com/" target="_blank" rel="noreferrer noopener">StarrBrowz &raquo;</a></h2>
          <p className="year">Beauty | Spring, 2023</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Wix</div>
          </div>
        </div>
      </div>
      
      
      
      
      
    </div>
    
    {/* Collapsible Section */}
    <Collapsible trigger={["See more", <ChevronDownIcon className="h-6 w-6 inline" />]}>
      <div className="three-cols mt-12">

      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/classifieds.png"
          alt="Oberlin Classifieds website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://oberlin-classifieds.com/dl/home" target="_blank" rel="noreferrer noopener">Oberlin Classifieds &raquo;</a></h2>
          <p className="year">Community | Spring, 2023</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Glide</div>
            <div className="tag">Airtable</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/aos.png"
          alt="Airport Oberlin Shuttle website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://airportoberlinshuttle.com/" target="_blank" rel="noreferrer noopener">Airport Oberlin Shuttle &raquo;</a></h2>
          <p className="year">Transportation | Summer, 2022</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/cle.png"
          alt="CLEPark website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://clepark.com/" target="_blank" rel="noreferrer noopener">CLEPark &raquo;</a></h2>
          <p className="year">Parking Lot | Spring, 2022</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/aep.png"
          alt="Airport Express Parking website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://cleairportexpressparking.com/" target="_blank" rel="noreferrer noopener">Airport Express Parking &raquo;</a></h2>
          <p className="year">Parking Lot | Spring, 2022</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/set.png"
          alt="Super Express Transportation website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client">Super Express Transportation</h2>
          <p className="year">Transportation | Spring, 2022</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">Weblium</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/cdc.png"
          alt="CDC Transportation website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://1cdctransportation.com" target="_blank" rel="noreferrer noopener">CDC Transportation &raquo;</a></h2>
          <p className="year">Transportation | Summer, 2021</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">GatsbyJS</div>
            <div className="tag">GitHub</div>
            <div className="tag">Vercel</div>
            <div className="tag">Airtable</div>
            <div className="tag">GraphQL</div>
            <div className="tag">Tailwind CSS</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/arisa.png"
          alt="Arisa Williams website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client">Arisa Williams Portfolio</h2>
          <p className="year">Portfolio | Summer, 2021</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">GatsbyJS</div>
            <div className="tag">GitHub</div>
            <div className="tag">Vercel</div>
            <div className="tag">Tailwind CSS</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/sakaizm.png"
          alt="SAKAIzm website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://sakaizm.com" target="_blank" rel="noreferrer noopener">SAKAIzm &raquo;</a></h2>
          <p className="year">Entertainment | Summer, 2021</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">GatsbyJS</div>
            <div className="tag">GitHub</div>
            <div className="tag">Vercel</div>
            <div className="tag">Airtable</div>
            <div className="tag">GraphQL</div>
            <div className="tag">Tailwind CSS</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/oberlin-classifieds.png"
          alt="Oberlin Classifieds website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client">Oberlin Classifieds</h2>
          <p className="year">Community | Spring, 2021</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">GatsbyJS</div>
            <div className="tag">GitHub</div>
            <div className="tag">Airtable</div>
            <div className="tag">GraphQL</div>
            <div className="tag">IFTTT</div>
            <div className="tag">Tailwind CSS</div>
          </div>
        </div>
      </div>
      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/mullane-mailer-1020.png"
          alt="Repair Revolution book mailer."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="design pill">Graphic Design</span></p>
          <h2 className="client">Book Mailer</h2>
          <p className="year">Mullane Literary | October, 2020</p>
        </div>
      </div>

      <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
        <StaticImage 
          src="../images/lishan.png"
          alt="Lishan Xue website."
          className="rounded-t-lg mb-5"
        />
        <div className="px-6 pt-3 py-8">
          <p><span className="web pill">Web Design/Development</span></p>
          <h2 className="client"><a href="https://lishanxue.com" target="_blank" rel="noreferrer noopener">Lishan Xue &raquo;</a></h2>
          <p className="year">Pianist | Summer, 2020</p>
          <div className="flex flex-wrap gap-2">
            <div className="tag">GatsbyJS</div>
            <div className="tag">Netlify</div>
            <div className="tag">GitHub</div>
            <div className="tag">Airtable</div>
            <div className="tag">GraphQL</div>
            <div className="tag">Bootstrap</div>
          </div>
        </div>
      </div>
        
        <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
          <StaticImage 
            src="../images/amina-and-amir.png"
            alt="The Awesome Adventures of Amina and Amir website."
            className="rounded-t-lg mb-5"
          />
          <div className="px-6 pt-3 py-8">
            <p><span className="web pill">Web Design/Development</span></p>
            <h2 className="client"><a href="https://www.aminaandamir.com" target="_blank" rel="noreferrer noopener">The Awesome Adventures of Amina and Amir &raquo;</a></h2>
            <p className="year">Childeren&rsquo;s Book | Summer, 2020</p>
            <div className="flex flex-wrap gap-2">
              <div className="tag">GatsbyJS</div>
              <div className="tag">Netlify</div>
              <div className="tag">GitHub</div>
              <div className="tag">Airtable</div>
              <div className="tag">GraphQL</div>
              <div className="tag">Bootstrap</div>
            </div>
          </div>
        </div>

        <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
          <StaticImage 
            src="../images/japanese.png"
            alt="Language learning materials."
            className="rounded-t-lg mb-5"
          />
          <div className="px-6 pt-3 py-8">
            <p><span className="design pill">Graphic Design</span></p>
            <h2 className="client">Language Learning Materials</h2>
            <p className="year">Spring, 2020</p>
          </div>
        </div>

        <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
          <StaticImage 
            src="../images/mullane-mailer-0220.png"
            alt="Ancestors in Our Genome book mailer."
            className="rounded-t-lg mb-5"
          />
          <div className="px-6 pt-3 py-8">
            <p><span className="design pill">Graphic Design</span></p>
            <h2 className="client">Book Mailer</h2>
            <p className="year">Mullane Literary | February, 2020</p>
          </div>
        </div>

        <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
          <StaticImage 
            src="../images/gwtp.png"
            alt="Get with the Program website."
            className="rounded-t-lg mb-5"
          />
          <div className="px-6 pt-3 py-8">
            <p><span className="web pill">Web Design/Development</span></p>
            <h2 className="client"><a href="https://www.gwtp.us" target="_blank" rel="noreferrer noopener">Get with the Program &raquo;</a></h2>
            <p className="year">Nonprofit | Summer, 2019</p>
            <div className="flex flex-wrap gap-2">
              <div className="tag">GatsbyJS</div>
              <div className="tag">Netlify</div>
              <div className="tag">GitHub</div>
              <div className="tag">Airtable</div>
              <div className="tag">GraphQL</div>
              <div className="tag">Bootstrap</div>
            </div>
          </div>
        </div>

        <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
          <StaticImage 
            src="../images/gwtp-flyer-0417.png"
            alt="Get with the Program Chemistry STEM Camp flyer."
            className="rounded-t-lg mb-5"
          />
          <div className="px-6 pt-3 py-8">
            <p><span className="design pill">Graphic Design</span></p>
            <h2 className="client">STEM Camp Flyer</h2>
            <p className="year">Get with the Program | April, 2017</p>
          </div>
        </div>

        <div className="dark:shadow-md shadow rounded-lg dark:bg-gray-900">
          <StaticImage 
            src="../images/gwtp-flyer-0417-2.png"
            alt="Get with the Program Family STEM Night flyer."
            className="rounded-t-lg mb-5"
          />
          <div className="px-6 pt-3 py-8">
            <p><span className="design pill">Graphic Design</span></p>
            <h2 className="client">Family STEM Night Flyer</h2>
            <p className="year">Get with the Program | April, 2017</p>
          </div>
        </div>
      </div>
    </Collapsible>
    </section>
    {/* End Collapsible Section */}
    {/* End Works */}
  </div>
  
  {/* More */}
  <section>
  <div className="bg-gray-100 dark:bg-gray-900 mt-20 grid grid-cols-1 md:grid-cols-2 gap-x-8">
    <div className="more">&nbsp;</div>
    <div className="p-6 m-auto">
      <h2><span>More About Me</span></h2>
      <h3>Languages</h3>
      <div className="flex flex-wrap gap-2">
        <div className="tag">English</div>
        <div className="tag">Japanese</div>
      </div>
      <h3 className="mt-3">Current Interests</h3>
      <div className="flex flex-wrap gap-2">
        <div className="tag">generative AI</div>
        <div className="tag">Framer</div>
        <div className="tag">Webstudio</div>
        <div className="tag">headless CMS</div>
        <div className="tag">no-code/low-code</div>
      </div>
    </div>
  </div>
  </section>
  {/* End More */}

  {/* Testimonial */}
  {/* <section>
  <div className="container mt-36">
    <div className="dark:shadow-md shadow p-6 rounded-lg dark:bg-gray-900 xl:w-2/3 mx-auto text-center">
    <StaticImage 
      src="../images/person.jpg"
      width={80}
      alt="Some Person."
      className="rounded-full -mt-16 mb-8"
    />
    <p><span className="font-serif">“</span>Ornare vel facilis sociosqu habitasse quasi, sollicitudin veritatis, aenean! Doloremque ipsum fermentum, eos blanditiis modi adipisicing eiusmod dolore? Accumsan lorem nibh dictumst eiusmod! Numquam scelerisque.<span className="font-serif">”</span></p>
    <p><span className="gradient-text font-bold text-2xl">John Doe</span><br /><span className="text-sm font-bold">Company, Inc.</span></p>
    </div>
  </div>
  </section> */}
  {/* End Testimonial */}

  {/* Footer */}
  <footer>
  <div className="mt-20 bg-gray-100 dark:bg-gray-900">
    <div className="container pt-12 text-center">
    <h2>Like what you see?</h2>
    <a href="mailto:arisa.williams@icloud.com"><button className="btn-gradient mt-3">Chat with me <MailIcon className="h-6 w-6 inline" /></button></a>
    

    <div className="max-w-md mx-auto mt-12 border-t dark:border-gray-50 border-gray-200 py-10">
    <p>{getCurrentYear()} &copy; Arisa</p>
    </div>
    </div>
  </div>
  </footer>
  {/* End Footer */}
</div>
}